@import '../../variable.scss';

@media (min-width: 768px) {
    .navbar-expand-md .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
        height: 98.8vh;
        background-color: $orange_main_color;
        padding: 0 !important;
        overflow-y: auto; /* Add this line to enable vertical scrolling if content overflows */
    }

    .navbar {
        padding: 0 !important;
    }

    .full-height-sidebar {
        height: 100vh !important;
        overflow-y: auto; /* Add this line to enable vertical scrolling if content overflows */
    }
}

@media (max-width: 768px) {
    .navbar-expand-md {
        padding: 20px;
    }

    .navbar {
        padding: 10px 20px !important;
    }
}



.flex-column {
    width: 100%;
    .logo{
        border-radius: 50%;
    }

    .nav-link {
        margin-bottom: 20px !important;
        font-family: 'Dosis-Bold', sans-serif;
        font-size: 18px;
        padding-left: 30px !important;

        &:hover {
            text-decoration: underline;
        }

        &.active {
            background-color: $blue_main_color;
            color: white !important;
        }
    }
}