.home-content {
    .loading-indicator {
        display: flex !important;
        align-items: center;
        justify-content: center;
    }

    .visible {
        display: block;
    }

    .disable {
        display: none;
    }
}