// styles.scss
@import '~bootstrap/scss/bootstrap';
@import './variable.scss';
@import './fonts.scss';

.custom-container {
  padding: 0;
}

body {
  font-family: 'Dosis', sans-serif;
}

.MuiTextField-root {
  &.custom-color {
    .MuiInputBase-root {
      color: $custom_textfield_black;
    }

    .MuiInputLabel-root {
      color: $custom_textfield_black;
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: $blue_main_color;
      border: 2px solid $cream_main_color;
    }

    &:hover .MuiOutlinedInput-notchedOutline {
      border-color: darken($orange_main_color, 10%);
      border: 2px solid $cream_main_color;
    }

    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: $orange_main_color;
      border: 2px solid $cream_main_color;
    }
  }

  &.custom-color-cancel {
    .MuiInputBase-root {
      color: $custom_textfield_black;
    }

    .MuiInputLabel-root {
      color: $custom_textfield_black;
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: $blue_main_color;
      border: 2px solid $cream_main_color;
    }

    &:hover .MuiOutlinedInput-notchedOutline {
      border-color: darken($orange_main_color, 10%);
      border: 2px solid $cream_main_color;
    }

    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: $orange_main_color;
      border: 2px solid $cream_main_color;
    }
  }
}

.MuiButton-root {
  &.custom-color {
    background-color: $blue_main_color;
    font-family: 'Dosis-Bold', sans-serif;
    color: white;
  }

  &:hover {
    background-color: $blue_main_color;
  }

  &.clicked {
    background-color: $blue_main_color;
  }

  &.custom-color-cancel {
    background-color: $orange_main_color;
    font-family: 'Dosis-Bold', sans-serif;
    color: white;
  }
}

.m-10 {
  margin: 10px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-5 {
  margin-top: 5px !important;
}

.ml-10{
  margin-left: 10px !important;
}

.p-0 {
  padding: 0 !important;
}

.error-message {
  color: red;
  font-size: 14px;
  font-weight: bold;
}

.text-center {
  text-align: center;
}

.text-disabled {
  color: lightgray;
}