.orders-content {
    padding: 50px;

    h1 {
        margin-bottom: 50px;
        font-family: 'Dosis-Bold', sans-serif;
    }

    .form-add {
        padding-top: 20px;

        .form-field {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: 20px 0;

            .total {
                font-family: "Dosis-Bold", sans-serif;
            }
        }
    }
    .btn-clear:hover{
        cursor: pointer;
    }
}