@import '../../variable.scss';

.login-content {
  background-color: $orange_main_color;
  color: #ffff;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .presentation {
    animation: fadeIn 2.5s ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .logo {
      width: 200px;
      height: 200px;
      border-radius: 50%;
    }

    .title {
      font-family: 'Dosis-Bold', sans-serif;
      text-align: center;
      padding: 20px 10px 0;
      color: #ffff;
    }

    h1,
    h5 {
      color: black;
      font-family: 'Dosis-Bold', sans-serif;
    }

    h1 {
      text-shadow: 1px 2px 2px #333333;
    }
  }

  .login-form {
    display: flex;
    flex-direction: column;
    // color: # !important;
    padding: 30px 0;
    width: 300px;

    .btn-login {
      margin-top: 20px !important;
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}