.tbl-content {
  width: 100%;

  .tbl-header {
    font-family: 'Dosis-Bold', sans-serif;
    font-size: 14px;
  }

  .status_success {
    font-family: 'Dosis-Bold', sans-serif;
    color: green;
  }

  .status_inprogress {
    @extend .status_success;
    color: red;
  }

  .action-btn {
    padding: 0 8px !important;
  }

  @media (min-width: 768px) {
    max-height: 300px !important;
  }
}

.tbl-pagination {
  display: flex;
  justify-content: flex-end;

  p {
    margin: 0 !important;
  }
}