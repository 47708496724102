@import '../../variable.scss';

.error-content {
    background-color: $orange_main_color;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    img {
        width: 350px;
        padding-bottom: 20px;
    }

    h3 {
        color: white;
        text-align: center;
    }
}