.dashboard-content {
    padding: 50px;

    h1 {
        text-align: center;
        font-family: 'Dosis-Bold', sans-serif;
    }
    .box{
        padding: 20px 0;
        h2{
            font-family: 'Dosis-Bold',sans-serif;
        }
    }
}