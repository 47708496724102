.profile-content {
    padding: 50px;

    h1 {
        font-family: 'Dosis-Bold', sans-serif;
    }

    .form-field {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 0;

        .fullname {
            width: 100%;
        }
    }

    @media (max-width: 768px) {
        .form-field {
            padding: 10px 0;
        }
    }
}