// src/styles/fonts.scss

@font-face {
    font-family: 'Dosis';
    src: local('Dosis'), url('./../public/Dosis-Regular.ttf') format('ttf'),
        url('./../public/Dosis-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Dosis-Bold';
    src: local('Dosis'), url('./../public/Dosis-Bold.ttf') format('ttf'),
        url('./../public/Dosis-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}